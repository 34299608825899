import { useEffect, useState } from 'react';
import { Alert, MyAlertProps } from './Home';
import Snackbar from '@mui/material/Snackbar';
import { useUserSettings } from '../../Contexts/UserSettingsContext';
import { Button } from "@mui/material";
import './Main.css';
import useDynamicStyles from '../Components/styles/DynamicStyles';
import { useNavigate } from 'react-router-dom';

export enum VERIFYSTATUS {
    VERIFIED = "VERIFIED",
    UNVERIFIED = "UNVERIFIED",
    PENDING = "PENDING",
    FAILED = "FAILED",
    EXPIRED = "EXPIRED",
}


const VerifyEmail = () => {

    const [alertProps, setAlertProps] = useState<MyAlertProps>({ severity: 'info' });
    const [showAlert, setShowAlert] = useState(false);
    const { state, dispatch } = useUserSettings();
    const styles = useDynamicStyles();
    const navigate = useNavigate();

    // get the token from the url
    const token = window.location.pathname.split('/')[2];
    console.log(token);

    const returnToHome = () => {
        setTimeout(() => {
            navigate('/');
        }, 5000);
    }

    useEffect(() => {
        if (state.userData.verified || !token) {
            navigate('/');
            return;
        }

        // send the token to the backend
        const apiUrl = process.env.REACT_APP_API_URL;
        const url = `${apiUrl}verify-email?code=${encodeURIComponent(token)}`;
        
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(res => {
            if (!res.ok) {
              throw new Error(`HTTP error! Status: ${res.status}`);
            }
            return res.json(); // Correctly return the JSON parsing promise
          })
        .then(data => {
            if (data.status === VERIFYSTATUS.VERIFIED) {
                dispatch({ type: 'SET_USER_DATA', payload: { email: state.userData.email, verified: false } });
                setAlertProps({
                    message: 'Email verified successfully',
                    severity: 'success',
                });
                setShowAlert(true);
                returnToHome();
            } else {
                throw new Error('Failed to verify email');
            }
        }).catch(err => {
            console.log(err.message);
            setAlertProps({
                message: 'Something went wrong. Please try again later.',
              severity: 'error',
            });
            setShowAlert(true);
            dispatch({ type: 'SET_USER_DATA', payload: { email: state.userData.email, verified: false } });
          })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, navigate]);

    return (
        <div className="verification-container">
            <Snackbar open={showAlert} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'left' }} onClose={() => setShowAlert(false)}>
                <Alert severity={alertProps.severity || 'info'} onClose={() => setShowAlert(false)}>
                    {alertProps.message}
                </Alert>
            </Snackbar>
            {!state.userData.verified ? (
                <div>
                    <h1 className="verification-title">Awaiting verification</h1>
                    <p>This shouldn't take long...</p> 
                </div>
            ) : (
                <div>
                    <h1 className="verification-title">Verification complete</h1>
                    <p>You can now access all the features of this app. Automatically redirecting in 5 seconds.</p>
                    <Button href='/' variant="contained" sx={[styles.button, {width: '200px'}]}>
                        Home
                    </Button>
                </div>
            )
            }
        </div>
    );
};

export default VerifyEmail;
