import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import "../styles/Components.css";
import Button from "@mui/material/Button";

export interface Result {
    image: string;
    title: string;
    description: string;
    price: number;
    appraisedValue: number;
    url: string;
}

interface ResultsProps {
    results: Array<Result>;
}

const Results = (props: ResultsProps) => {
    const { results } = props;

    return (
        <div className="results-root-container">
            <Grid container spacing={2}>
                {results && results.map((result, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                        <Button className="result-container result-enter-active" style={{ animationDelay: `${index * 0.4}s` }}
                             onClick={() => window.open(result.url, "_blank")}>
                                <Box className="result-image" component="img" src={result.image} />
                                <Box className="result-info">
                                    <h3>{result.title}</h3>
                                    <p>Potential Profit: ${result.appraisedValue - result.price}</p>
                                </Box>
                        </Button>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
}

export default Results;