import Search from "../Components/Search";
import './Main.css';
import Results, { Result } from "../Components/ReusableComponents/Results";
import { useState, useEffect } from "react";
import { Snackbar } from "@mui/material";
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import React from "react";
import FormDialog from "../Components/ReusableComponents/Dialog";
import { useUserSettings } from "../../Contexts/UserSettingsContext";
import { VERIFYSTATUS } from "../Pages/VerifyEmail";


export interface User {
    id: string;
    email: string;
    verified: boolean;
}

export interface MyAlertProps {
    severity?: 'success' | 'info' | 'warning' | 'error' | undefined;
    message?: string;
}

export const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={20} ref={ref} variant="filled" {...props} />;
});

const Home = () => {

    const [results, setResults] = useState<Array<Result>>([]);
    const [alertProps, setAlertProps] = useState<MyAlertProps>({ severity: 'info' });
    const [showAlert, setShowAlert] = useState(false);
    const [openForm, setOpenForm] = useState(false);
    const { state, dispatch } = useUserSettings();


    const handleFormSubmit = (email: string) => {
        console.log("Email submitted for verification:", email);
        const apiUrl = process.env.REACT_APP_API_URL;
        const url = `${apiUrl}submit-email`;

        // Sending email to the backend for verification
        return fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ recipient: email }),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            if (data.status === VERIFYSTATUS.PENDING) {
                setAlertProps({
                    message: 'Verification email sent. Please check your inbox.',
                    severity: 'info',
                });
                
            } else if (data.status === VERIFYSTATUS.VERIFIED) {
                setAlertProps({
                    message: 'Email already verified.',
                    severity: 'info',
                });
                dispatch({ type: 'SET_USER_DATA', payload: { email: email, verified: true } });
            }

            return data.status;
        })
        .catch(error => {
            console.error('Error during email verification:', error);
            setAlertProps({
                message: error.message || 'Failed to send verification email.',
                severity: 'error',
            });
            return VERIFYSTATUS.FAILED;
        });
    };
    
    

    useEffect(() => {
        if (alertProps.message) {
            setShowAlert(true);
        }
    }, [alertProps])

    const handleClose = (_event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    };

    return (
        <div className="home-container">
            <Snackbar open={showAlert} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'left' }}>
                <Alert onClose={handleClose} severity={alertProps.severity || 'info'}>
                    {alertProps.message}
                </Alert>
            </Snackbar>
            <FormDialog
                handleSubmit={handleFormSubmit}
                open={openForm}
                setOpen={setOpenForm}
            />
            <div className="home-title-container">
                <img src="/donbot-icon.png" alt="DonBot" className="header-logo" />
                <div className="home-title-text-container">
                    <h1 className="home-title">Donum Robotum</h1>
                    <p>™</p>
                </div>
            </div>
            <Search state={state} setOpenForm={setOpenForm} results={results} setResults={setResults} setAlert={setAlertProps}/>
            <Results results={results}/>
        </div>
    )
}

export default Home;