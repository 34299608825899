import DarkMode from '@mui/icons-material/DarkModeOutlined';
import LightMode from '@mui/icons-material/LightModeOutlined';
import { useUserSettings } from "../../Contexts/UserSettingsContext";
import { Link } from 'react-router-dom';
import ColorLens from '@mui/icons-material/ColorLensOutlined';
import Menu from './ReusableComponents/Menu';
import { useState } from 'react';
import './styles/Components.css';
// import { useNavigate } from 'react-router-dom';

const Header = () => {
    const { state, dispatch } = useUserSettings();
    // const navigate = useNavigate();
    const [accentMenuAnchorEl, setAccentMenuAnchorEl] = useState<null | HTMLElement>(null);
    const isMobile = window.innerWidth < 768;

    const handleAccentMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAccentMenuAnchorEl(event.currentTarget);
    };

    const handleCloseAccentMenu = () => {
        setAccentMenuAnchorEl(null);
    };

    const DiscordIcon = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M13.545 2.907a13.2 13.2 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.2 12.2 0 0 0-3.658 0 8 8 0 0 0-.412-.833.05.05 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.04.04 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032q.003.022.021.037a13.3 13.3 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019q.463-.63.818-1.329a.05.05 0 0 0-.01-.059l-.018-.011a9 9 0 0 1-1.248-.595.05.05 0 0 1-.02-.066l.015-.019q.127-.095.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.05.05 0 0 1 .053.007q.121.1.248.195a.05.05 0 0 1-.004.085 8 8 0 0 1-1.249.594.05.05 0 0 0-.03.03.05.05 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.2 13.2 0 0 0 4.001-2.02.05.05 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.03.03 0 0 0-.02-.019m-8.198 7.307c-.789 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612m5.316 0c-.788 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612" />
            </svg>
        )
    }

    const accentColorMenuItems = [
        // Add 9 accent colours. Main colours but less saturated.
        { color: '#D32F2F', onClick: () => dispatch({ type: 'SET_ACCENT_COLOR', payload: '#D32F2F' }) },
        { color: '#00c400', onClick: () => dispatch({ type: 'SET_ACCENT_COLOR', payload: '#00c400' }) },
        { color: '#64cbcc', onClick: () => dispatch({ type: 'SET_ACCENT_COLOR', payload: '#64cbcc' }) },
        { color: '#FFA000', onClick: () => dispatch({ type: 'SET_ACCENT_COLOR', payload: '#FFA000' }) },
        { color: '#a479e0', onClick: () => dispatch({ type: 'SET_ACCENT_COLOR', payload: '#a479e0' }) },
        { color: '#455A64', onClick: () => dispatch({ type: 'SET_ACCENT_COLOR', payload: '#455A64' }) },
    ];

    return (
        <div className="header-container">
            <div className="header-container-left">
                <Link to="/" className="header-item">Home</Link>
                <Link to="/about" className="header-item">About</Link>
                <Link to='/about#help' className="header-item">Help</Link>
            </div>
            <div className="header-container-right">
                <div className='header-item header-button'>
                    <button className='join-now-button' onClick={() => {
                        // navigate('/purchase')

                        window.open("https://discord.gg/fHqtsuWZnU", '_blank')
                    }} >
                        {/* {state.userData && state.userData.isPremium ? 'Dashboard' : 'Join'} */}
                        {isMobile ? 'Join' : 'Join Now'}
                    </button>
                </div>

                <a href="https://discord.gg/fHqtsuWZnU" target="_blank" rel="noreferrer" className="header-item header-button">
                    {DiscordIcon()}
                </a>

                {state.darkMode ? (
                    <DarkMode
                        className="header-item header-button"
                        onClick={() => dispatch({ type: 'TOGGLE_DARK_MODE' })}
                    />
                ) : (
                    <LightMode
                        className="header-item header-button"
                        onClick={() => dispatch({ type: 'TOGGLE_DARK_MODE' })}
                    />
                )}
                
                <button onClick={handleAccentMenuClick} style={{ width: 'fit-content', border: 'none', backgroundColor: 'transparent', display: isMobile ? 'none' : 'block' }}>
                    <ColorLens className="header-item header-button" />
                </button>
                <Menu
                    anchorEl={accentMenuAnchorEl}
                    handleClose={handleCloseAccentMenu}
                    menuItems={accentColorMenuItems}
                    state={state}
                />
            </div>
        </div>
    )
}

export default Header;