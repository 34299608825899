import { useUserSettings } from '../../../Contexts/UserSettingsContext';

const useDynamicStyles = () => {
    const { state } = useUserSettings();

    const drawerWidth = 240;
    const isMobile = window.innerWidth < 768; // Simple check for mobile screen size

    return {
        button: {
            // red #D32F2F
            backgroundColor: state.accentColor,
            color: "#FFFFFF",
            height: isMobile ? "48.5px" : "65.2px", // Smaller height for mobile
            width: isMobile ? "122px" : "150px",
            borderRadius: "5px",
            border: "none",
            fontSize: isMobile ? "1rem" : "1.3rem",
            fontWeight: "normal",
            cursor: "pointer",
            transition: "background-color 0.3s ease-in-out",
            "&:hover": {
            backgroundColor: state.accentColor + "cc",
            }
        },
        subscribeButton: {
            backgroundColor: state.accentColor,
            color: "#FFFFFF",
            height: "48.5px",
            width: "150px",
            borderRadius: "5px",
            border: "none",
            fontSize: "1.2rem",
            fontWeight: "normal",
            cursor: "pointer",
            transition: "background-color 0.3s ease-in-out",
            "&:hover": {
            backgroundColor: state.accentColor + "cc",
            }
        },
        buttonDisabled: {
            backgroundColor: state.accentColor + "40",
            color: "#FFFFFF",
            height: isMobile ? "48.5px" : "65.2px", // Smaller height for mobile
            width: isMobile ? "122px" : "150px",
            borderRadius: "5px",
            border: "none",
            fontSize: "1.3rem",
            fontWeight: "normal",
            cursor: "not-allowed",
        },
        input: {
            color: "#bababa",
            fontSize: "1.3rem",
        },
        select: {
            color: state.darkMode ? '#e0e0e0' : '#282c34',
            fontSize: isMobile ? "1.0rem" : "1.4rem",
            fontWeight: "normal",
            width: isMobile ? "122px" : "150px",
            height: isMobile ? "48.5px" : "65.2px",
            borderRadius: "5px",
            '& .MuiOutlinedInput-notchedOutline': {
                borderWidth: '1px',
                borderColor: state.darkMode ? '#e0e0e0' : '#282c34',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderWidth: '1px',
                borderColor: state.accentColor, // Red on hover
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: state.accentColor, // Set the border on focus to red
            },
            '& .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon': {
            color: state.darkMode ? '#e0e0e0' : '#282c34',
            },
            '& .MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSelect-icon MuiSelect-iconOutlined css-1636szt': {
                color: state.darkMode ? '#e0e0e0' : '#282c34',
            },
        },
        textField: {
            '.css-hwkq3c-MuiInputBase-root-MuiFilledInput-root': {
                backgroundColor: 'rgba(0, 0, 0, 0.12)',
            },
            '& .MuiInputLabel-root': {
                color: state.darkMode ? '#e0e0e0' : '#282c34',
                fontSize: '1rem',
                fontWeight: 'normal',
            },
            '& .MuiInputLabel-root.Mui-focused': {
                color: state.accentColor,
            },
            '& .MuiFilledInput-underline:before': {
                borderBottomColor: state.darkMode ? '#e0e0e0' : '#282c34',
            },
            '& .MuiFilledInput-underline:after': {
                borderBottomColor: state.accentColor,
            },
        },
        drawer: {
            width: isMobile ? 0 : drawerWidth,
            overflow: 'hidden',
            flexShrink: 0,
            '& .MuiDrawer-paper': {
                width: isMobile ? 0 : drawerWidth,
                boxSizing: 'border-box',
                top: '64px',
                height: 'calc(95vh - 64px)',
                backgroundColor: !state.darkMode ? '#e0e0e0' : '#282c34',
                color: !state.darkMode ? '#282c34' : '#e0e0e0',
            },
            '& .MuiListItem-root': {
                '&:hover': {
                    backgroundColor: state.accentColor,
                    color: '#FFFFFF',
                },
            },
            '.css-hyum1k-MuiToolbar-root': {
                    display: 'none',
                },
            },
        drawerBox: {
            display: 'grid',
            left: isMobile ? 0 : 240,
            justifyContent: 'center',
            position: 'fixed',
            width: isMobile ? '100vw' : 'calc(100vw - 240px)',
        },
        drawerContent: {
            display: 'flex',
            marginTop: isMobile ? '0' : '48px', 
            paddingLeft: isMobile ? '24px' : '48px',
            width: '93%', 
            overflow: isMobile ? 'scroll' : 'hidden',
            height: isMobile ? '70vh' : 'calc(100vh - 64px)', // Adjusted height
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            flexDirection: 'column', color: !state.darkMode ? '#282c34' : '#e0e0e0',
            paddingBottom: isMobile ? '50px' : '0',
        },
        dialog: {
            '& .MuiDialog-paper': {
                backgroundColor: !state.darkMode ? '#e0e0e0' : '#282c34',
                color: !state.darkMode ? '#282c34' : '#e0e0e0',
            },
            '& .MuiInputLabel-root': {
                color: !state.darkMode ? '#282c34' : '#e0e0e0',
            },
            '& .MuiInputBase-root': {
                color: !state.darkMode ? '#282c34' : '#e0e0e0',
            },
            '& .MuiInput-underline:before': {
                borderBottomColor: !state.darkMode ? '#282c34' : '#e0e0e0',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: state.accentColor,
            },
            '& .css-qfso29-MuiTypography-root-MuiDialogContentText-root': {
                color: state.darkMode ? '#e0e0e0' : '#282c34',
                link: {
                    color: state.accentColor,
                },
            },
            '& MuiTypography-root MuiDialogContentText-root MuiTypography-body1 MuiDialogContentText-root css-qfso29-MuiTypography-root-MuiDialogContentText-root': {
                color: state.darkMode ? '#e0e0e0' : '#282c34',
            },
            '& .css-1e40b6s-MuiTypography-root-MuiDialogContentText-root': {
                color: state.darkMode ? '#e0e0e0' : '#282c34',
                link: {
                    color: state.accentColor,
                },
            },
            
        },
    };
};

export default useDynamicStyles;
