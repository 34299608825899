import Drawer from "../Components/ReusableComponents/Drawer";
import Info from '@mui/icons-material/InfoOutlined';
import Handshake from '@mui/icons-material/HandshakeOutlined';
import Privacy from '@mui/icons-material/PrivacyTipOutlined';
import Help from '@mui/icons-material/HelpOutlineOutlined';
import Email from '@mui/icons-material/EmailOutlined';
import Work from '@mui/icons-material/WorkOutlineOutlined';
import Bitcoin from '@mui/icons-material/CurrencyBitcoinOutlined';
import Bug from '@mui/icons-material/BugReportOutlined';
import { useUserSettings } from "../../Contexts/UserSettingsContext";
import { Button } from "@mui/material";


const PolicyPages = () => {
    const items = [
        { label: 'About Us', content: <AboutContent />, icon: <Info />, tag: '#' },
        { label: 'Community', content: <CommunityContent />, 
            icon: <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M13.545 2.907a13.2 13.2 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.2 12.2 0 0 0-3.658 0 8 8 0 0 0-.412-.833.05.05 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.04.04 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032q.003.022.021.037a13.3 13.3 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019q.463-.63.818-1.329a.05.05 0 0 0-.01-.059l-.018-.011a9 9 0 0 1-1.248-.595.05.05 0 0 1-.02-.066l.015-.019q.127-.095.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.05.05 0 0 1 .053.007q.121.1.248.195a.05.05 0 0 1-.004.085 8 8 0 0 1-1.249.594.05.05 0 0 0-.03.03.05.05 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.2 13.2 0 0 0 4.001-2.02.05.05 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.03.03 0 0 0-.02-.019m-8.198 7.307c-.789 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612m5.316 0c-.788 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612"/>
                  </svg>, tag: '#community' },
        { label: 'Privacy Policy', content: <PrivacyContent />, icon: <Privacy />, tag: '#privacy' },
        { label: 'Terms', content: <TermsContent />, icon: <Handshake />, tag: '#terms' },
        { label: 'Help', content: <HelpContent />, icon: <Help />, tag: '#help' },
        { label: 'Contact', content: <ContactContent />, icon: <Email />, tag: '#contact' },
      ];

    return (
        <Drawer items={items} />
    )
}

// TODO: There's a lot of repeated code here. I should refactor this to use a single component with props to determine the content.

export default PolicyPages;

const AboutContent = () => {
    const { state } = useUserSettings();

    return (
        <div style={{ padding: '20px', backgroundColor: state.darkMode ? '#282c34' : '#e0e0e0', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
            <h1 style={{ color: state.darkMode ? '#e0e0e0' : '#282c34' }}>About DonumRobotum</h1>
            <p>
                "Donum Robotum," loosely translated from Greek, encapsulates the essence of "gift of automation" or "gift from a robot." This concept is closely mirrored in our service's nickname, "DonBot" – your personal digital assistant in finding the best deals. At the heart of DonumRobotum lies a dedication to harnessing the power of technology to simplify and enhance your shopping experience.
            </p>
            <p>
                Our platform, DonumRobotum, is an innovative search engine tailored for finding the best deals in used vehicles and homes. By collating listings from renowned platforms like Offerup and Facebook Marketplace, we provide a comprehensive, one-stop solution. Our unique algorithm evaluates each offering against current market values, ensuring you get unmatched deals with a few clicks.
            </p>
            <p>
                Our journey began with a simple mission: to streamline the often overwhelming process of online shopping. We strive to make the hunt for used vehicles and homes not just efficient but also reliable and user-friendly. Whether you're a bargain hunter with an entrepreneurial spirit, a first-time buyer, or someone who values time and quality, DonumRobotum is here to transform your shopping experience.
            </p>
        </div>
    )
}

const CommunityContent = () => {
    const { state } = useUserSettings();

    return (
        <div style={{ padding: '20px', backgroundColor: state.darkMode ? '#282c34' : '#e0e0e0', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
            <h1 style={{ color: state.darkMode ? '#e0e0e0' : '#282c34' }}>Join Our Discord</h1>
            <p>
                Join our community on Discord to connect with other entrepreneurs and bargain hunters. Share your experiences, ask questions, and contribute to the ongoing development of DonumRobotum.
                We value your input and are committed to creating a supportive, interactive community. We look forward to welcoming you to our growing community!
            </p>
            <Button sx={{ backgroundColor: state.darkMode ? '#282c34' : '#e0e0e0', color: state.darkMode ? '#e0e0e0' : '#282c34', margin: '10px', 
                '&:hover': { backgroundColor: state.accentColor, color: state.darkMode ? '#282c34' : '#e0e0e0' } }}
                variant="contained" href="https://discord.gg/fHqtsuWZnU" target="_blank" 
                    startIcon={
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" viewBox="0 0 16 16">
                            <path d="M13.545 2.907a13.2 13.2 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.2 12.2 0 0 0-3.658 0 8 8 0 0 0-.412-.833.05.05 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.04.04 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032q.003.022.021.037a13.3 13.3 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019q.463-.63.818-1.329a.05.05 0 0 0-.01-.059l-.018-.011a9 9 0 0 1-1.248-.595.05.05 0 0 1-.02-.066l.015-.019q.127-.095.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.05.05 0 0 1 .053.007q.121.1.248.195a.05.05 0 0 1-.004.085 8 8 0 0 1-1.249.594.05.05 0 0 0-.03.03.05.05 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.2 13.2 0 0 0 4.001-2.02.05.05 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.03.03 0 0 0-.02-.019m-8.198 7.307c-.789 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612m5.316 0c-.788 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612"/>
                        </svg> }>
                Join Now
            </Button>
        </div>
    )
}

const PrivacyContent = () => {
    const { state } = useUserSettings();

    return (
        <div style={{ padding: '20px', backgroundColor: state.darkMode ? '#282c34' : '#e0e0e0', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
            <h1 style={{ color: state.darkMode ? '#e0e0e0' : '#282c34' }}>Privacy Policy</h1>
            <p>
                At DonumRobotum, we prioritize your privacy. This policy outlines our commitment to protect your personal information and ensure a transparent and secure user experience.
            </p>
            <p>
                We collect your email to personalize your experience, verify your identity, and send service updates. Your email is essential for accessing our tailored services and receiving important communications. Rest assured, this information is used strictly for these purposes, following stringent data protection laws.
            </p>
            <p>
                Our data security measures are robust, designed to safeguard your information from unauthorized access. We do not sell your personal data; it may be shared with trusted partners solely to enhance our services, under strict confidentiality agreements.
            </p>
            <p>
                You retain full control over your personal information. You can access, correct, or delete your data at any time. Opting out of promotional emails is straightforward—simply follow the unsubscribe instructions in any marketing communication from us.
            </p>
            <p>
                For any questions about our privacy practices, feel free to contact us. Your trust and security are our highest priority.
            </p>
        </div>
    );
}



const TermsContent = () => {
    const { state } = useUserSettings();

    return (
        <div style={{ padding: '20px', backgroundColor: state.darkMode ? '#282c34' : '#e0e0e0', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
            <h1 style={{ color: state.darkMode ? '#e0e0e0' : '#282c34' }}>Terms and Conditions</h1>
            <p>
                Welcome to DonumRobotum. By accessing our service, you agree to be bound by these terms and conditions. Please read them carefully.
            </p>
            <h2>Service Use</h2>
            <p>
                DonumRobotum provides a search engine for finding deals on used vehicles and homes. Users are responsible for any decisions made based on information provided by our service.
            </p>
            <h2>Accounts and Membership</h2>
            <p>
                To access certain features of our service, you must register and provide your email. You can choose between free access with ads, paid access without ads, and a premium version for higher capacity usage.
            </p>
            <h2>Limitation of Liability</h2>
            <p>
                DonumRobotum is not liable for any direct, indirect, incidental, or consequential damages arising from your use of our service or any information provided by it.
            </p>
        </div>
    )
}

const HelpContent = () => {
    const { state } = useUserSettings();

    return (
        <div style={{ padding: '20px', backgroundColor: state.darkMode ? '#282c34' : '#e0e0e0', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
            <h1 style={{ color: state.darkMode ? '#e0e0e0' : '#282c34' }}>How to Use</h1>
            <p>
                DonumRobotum is a search engine for finding deals on used vehicles and homes (coming soon). To use our service, simply enter your desired search parameters and click search. Our algorithm will then display the best deals based on your criteria.
            </p>
            <h2>Search Parameters</h2>
            <p>
                You can customize your search by typing the following parameters: <br />
                $find deal near &lt;zipcode&gt; under $&lt;budget&gt; (optional: margin $&lt;profit&gt;) <br />
                For example: $find deal near 92223 under $5000 margin $1000 <br />
            </p>
        </div>
    )
}

const ContactContent = () => {
    const { state } = useUserSettings();

    const email = 'drew.ng3nuity@gmail.com';

    const emailButtonArray = [
        {label: 'Blockchain', subject: 'Blockchain is here! Let\'s talk.', icon: <Bitcoin />},
        {label: 'Business', subject: 'Hey! Let\'s do some business.', icon: <Work />},
        {label: 'Feedback', subject: 'I have some feedback for you.', icon: <Help />},
        {label: 'Report a Bug', subject: 'I found a bug.', icon: <Bug />},
        {label: 'Other', subject: '', icon: <Email />},
    ];

    return (
        <div style={{ padding: '20px', backgroundColor: state.darkMode ? '#282c34' : '#e0e0e0', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
            <h1 style={{ color: state.darkMode ? '#e0e0e0' : '#282c34' }}>Contact Me About:</h1>
            <div className='email-button-container'>
                {emailButtonArray.map((button, index) => {
                    return (
                    <Button key={index} className='email-button' sx={{ backgroundColor: state.darkMode ? '#282c34' : '#e0e0e0', color: state.darkMode ? '#e0e0e0' : '#282c34', margin: '10px', 
                        '&:hover': { backgroundColor: state.accentColor, color: state.darkMode ? '#282c34' : '#e0e0e0' } }}
                     variant="contained" startIcon={button.icon} href={`mailto: ${email}?subject=${button.subject}`}>
                        {button.label}
                    </Button> 
                )})}
            </div>
        </div>
    )
}
