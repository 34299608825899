import Box from '@mui/material/Box';
import MUIDrawer from '@mui/material/Drawer';
import { useLocation } from 'react-router-dom';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useEffect, useState } from 'react';
import { useUserSettings } from '../../../Contexts/UserSettingsContext';
import useDynamicStyles from '../styles/DynamicStyles';

// This is a reusable component that can be used to create a drawer with a list of items and content.

interface DrawerItem {
    label: string;
    icon: JSX.Element;
    content: JSX.Element;
    tag: string;
}

export interface DrawerProps {
    items: Array<DrawerItem>;
}


const Drawer = (props: DrawerProps) => {
    const { state } = useUserSettings();
    const styles = useDynamicStyles();

    const location = useLocation();
    const hash = location.hash;

    const [drawerIndex, setDrawerIndex] = useState(0);

    // Find the index of the item with the matching tag
    useEffect(() => {
        // If the hash is not found, set the index to the first item
        for (let i = 0; i < props.items.length; i++) {
            if (props.items[i].tag === hash) {
                setDrawerIndex(i);
                break;
            } else {
                setDrawerIndex(0);
            }
        }
    }, [hash, props.items]);


    return (
        <Box sx={styles.drawerBox}>
            <MUIDrawer
                sx={styles.drawer}
                variant="permanent"
                anchor="left"
                >
                <Divider />
                <List>
                {props.items.map((item, index) => (
                    <ListItem key={item.label} disablePadding>
                    <ListItemButton onClick={() => setDrawerIndex(index)}>
                        <ListItemIcon sx={{ color: !state.darkMode ? '#282c34' : '#e0e0e0' }}>
                            {item.icon}
                        </ListItemIcon>
                        <ListItemText primary={item.label} />
                    </ListItemButton>
                    </ListItem>
                ))}
                </List>
            </MUIDrawer>
            <Box
                component="main"
                sx={styles.drawerContent}>
                <Typography sx={{width: 'inherit'}} paragraph>
                    {props.items[drawerIndex].content}
                </Typography>
            </Box>
        </Box>
    );
}

export default Drawer;