import React, { useCallback } from "react";
import { Button, FormControl, InputLabel, LinearProgress, MenuItem, Select, TextField } from "@mui/material";
import { UserSettingsState } from "../../Contexts/UserSettingsContext";
import { Result } from "./ReusableComponents/Results";
import './styles/Components.css';
import { MyAlertProps } from "../Pages/Home";
import useDynamicStyles from "./styles/DynamicStyles";

export enum SEARCHSTATUS {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  NOTFOUND = "NOT_FOUND",
}

interface SearchProps {
  results: Result[];
  state: UserSettingsState;
  setOpenForm: React.Dispatch<React.SetStateAction<boolean>>;
  setResults: React.Dispatch<React.SetStateAction<any[]>>;
  setAlert: (props: MyAlertProps) => void;
}

const Search = (props: SearchProps) => {
  const { setResults } = props;
  const [query, setQuery] = React.useState("");
  const [preset, setPreset] = React.useState("default");
  const [buttonDisabled, setButtonDisabled] = React.useState(false);
  const [error, setError] = React.useState(false);
  const styles = useDynamicStyles();
  const isMobile = window.innerWidth < 768;

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      sendQuery();
    }
  };

  const validateQuery = (query: string): boolean => {
    if (!query.trim()) {
      return false;
    }

    const pattern = /^\$find deal near\s+\d{5}\s+under \$\d+(?:\s+margin \$\d+)?$/;
    return pattern.test(query);
  };

  const sendQuery = useCallback(() => {

    if (!props.state.userData || !props.state.userData.verified) {
      props.setOpenForm(true);
      return false;
    }

    const email = props.state.userData.email || '';

    if (!email) {
      props.setAlert({
        message: 'Please resubmit your email for verification',
        severity: 'info',
      });
      
      props.setOpenForm(true);
      return;
    }

    if (buttonDisabled) {
      return false;
    }
    
    if (!validateQuery(query)) {
      props.setAlert({
        message: 'Invalid query format',
        severity: 'error',
      })
      setError(true);
      setButtonDisabled(false);
      return;
    }

    setError(false);

    props.setAlert({
      message: 'Searching for deals...',
    })
    
    setButtonDisabled(true);


    const searchString = query.trim();
    const apiUrl = process.env.REACT_APP_API_URL;    
    const url = new URL('search', apiUrl); // Create a URL object

    // Add query parameters to the URL
    url.searchParams.append('query', searchString);
    url.searchParams.append('user_email', email);

  fetch(url.toString()) // Convert the URL object back to a string
    .then(res => {
      if (!res.ok) {
        console.log(res);
        throw new Error(`HTTP error! Status: ${res.status}`);
      }

      if (res.status === 204) {
        return;
      }

      return res.json(); // Correctly return the JSON parsing promise
    })
    .then(data => {

      if (!data) {
        props.setAlert({
          message: 'No deals found',
          severity: 'warning',
        });
        return;
      }

      setResults(currentResults => [data as Result, ...currentResults]);

      props.setAlert({
        message: `Found deals!`,
        severity: 'success',
      });
    })
    .catch(err => {
      console.log(err);
      props.setAlert({
        message: 'Something went wrong. Please try again later.',
        severity: 'error',
      });
    })
    .finally(() => {
      setButtonDisabled(false);
    });

// eslint-disable-next-line
  }, [query, setResults, props.state.userData.verified, buttonDisabled]);


  const desktopComponents = (
    <>
      <div className="search-item-zero">
          <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel htmlFor="my-dropdown" style={styles.input}>
            Category
          </InputLabel>
          <Select
            className="preset-select"
            value={preset}
            onChange={(e) => {
              setPreset(e.target.value);
            }}
            sx={styles.select}
            label="Category"
          >
            <MenuItem value="default">Vehicles</MenuItem>
            <MenuItem value={10} disabled={true}>
              Homes
            </MenuItem>
          </Select>
        </FormControl>
      </div>

      <div className="search-item">
        <TextField
          error={error}
          helperText={error ? 'Please enter a search query' : ''}
          label="Find Deal"
          onKeyDown={handleKeyDown}
          variant="filled"
          placeholder="$find deal near 92223 under $5000 margin $1000"
          fullWidth={true}
          onChange={(e) => {
            setQuery(e.target.value);
          }}
          sx={styles.textField}
          inputProps={{
            style: {
              color: props.state.darkMode ? '#e0e0e0' : '#282c34',
              fontSize: "1.5rem",
              fontWeight: "normal",
            },
          }}
          autoFocus={true}
        />
      </div>
      <div className="search-item-two">
          <Button onClick={sendQuery} variant="contained" style={buttonDisabled ? styles.buttonDisabled : styles.button}>
            Search
          </Button>
      </div>
    </>
  )

  const mobileComponents = (
    <>
        <TextField
          error={error}
          helperText={error ? 'Please enter a search query' : ''}
          label="Find Deal"
          onKeyDown={handleKeyDown}
          variant="filled"
          placeholder="$find deal near 92223 under $5000 margin $1000"
          fullWidth={true}
          onChange={(e) => {
            setQuery(e.target.value);
          }}
          sx={styles.textField}
          inputProps={{
            style: {
              color: props.state.darkMode ? '#e0e0e0' : '#282c34',
              fontSize: "1.5rem",
              fontWeight: "normal",
            },
          }}
          autoFocus={true}
        />
          <div className="search-mobile-lower-view">
            <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel htmlFor="my-dropdown" style={styles.input}>
              Category
            </InputLabel>
            <Select
              className="preset-select"
              value={preset}
              onChange={(e) => {
                setPreset(e.target.value);
              }}
              sx={styles.select}
              label="Category"
            >
              <MenuItem value="default">Vehicles</MenuItem>
              <MenuItem value={10} disabled={true}>
                Homes
              </MenuItem>
            </Select>
          </FormControl>
          <div className="search-item-two">
          <Button onClick={sendQuery} variant="contained" disabled={buttonDisabled} style={buttonDisabled ? styles.buttonDisabled : styles.button}>
            Search
          </Button>
        </div>
      </div>
    </>
  )


  return (
    <div className="search-container-root">
      <div className="search-container">
        {isMobile ? mobileComponents : desktopComponents}
      </div>
      <div className="search-item-three">
        <LinearProgress color="secondary" style={buttonDisabled ? { display: 'block' } : { display: 'none' }} />
      </div>
    </div>
  );
};

export default Search;
