import { Button } from '@mui/material';
import useDynamicStyles from "./styles/DynamicStyles";
import React from 'react';

const ProductCard = () => {
    const styles = useDynamicStyles();
    const stripeUrl = 'https://buy.stripe.com/cN2aIH2ASekVbLi3cd';

    return (
        <div className='product-card-container' onClick={() => {
            window.open(stripeUrl, '_blank');
        }}>
            <h1 className='product-card-title'>Donum Robotum Premium</h1>
            <div className='product-card-content'>
                <p>Unlock the full potential of DonBot and gain access to all features. 
                    Don will find the best deals in your area within minutes, saving you both money and time.</p>
                <div className='product-card-features-container'>
                    <div className='product-card-features-title'>
                        <h2>Features include:</h2>
                    </div>
                    <ul className='product-card-list'>
                        <li>Searches Offerup & Facebook Marketplace</li>
                        <li>Upgrades to 15 searches per day</li>
                        <li>Extensive guide to start flipping today</li>
                    </ul>
                </div>
            </div>
            <div className='product-card-price-container'>
                <p className='product-card-price'>$14.99/month</p>
                <Button variant='contained'
                    style={styles.subscribeButton}>
                        Subscribe
                </Button>

                <p className='product-card-disclaimer'>Cancel anytime.</p>
            </div>
        </div>
    );
}

export default ProductCard;