import './App.css';
import Home from './Main/Pages/Home';
import { UserSettingsProvider } from './Contexts/UserSettingsContext';
import StyledGlobalStyles from './GlobalStyles';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './Main/Components/Header';
import About from './Main/Pages/PolicyPages';
import Footer from './Main/Components/Footer';
import VerifyEmail from './Main/Pages/VerifyEmail';
import Payment from './Main/Pages/ProductPage';


function App() {
  return (
    <BrowserRouter>
      <UserSettingsProvider>
        <StyledGlobalStyles />
        <div className="App">
          <header className="App-header">
            <Header />
            <Routes>
              <Route path="/" Component={Home} />
              <Route path="/about" Component={About} />
              <Route path="/purchase" Component={Payment} />
              <Route path="/verify/*" Component={VerifyEmail} />
            </Routes>
            <Footer />
          </header>
        </div>
      </UserSettingsProvider>
    </BrowserRouter>
  );
}

export default App;
