import { Link } from 'react-router-dom';
import './styles/Components.css';

const Footer = () => {
    const currentYear = new Date().getFullYear();
    const name = "DRFALCOEW";

    return (
        <div className="footer-container">
            <div className="footer-container-left">
                <a className="footer-item" href="https://www.linkedin.com/in/drfalcoew/">LinkedIn</a>
                <a className="footer-item" href="https://www.github.com/drfalcoew">GitHub</a>
                <Link to="/about#contact" className="header-item">Contact</Link>
            </div>
            <p className="footer-item-label">© {currentYear} {name}</p>
            <div className="footer-container-right">
                <Link to="/about#privacy" className="footer-item">Privacy</Link>
                <Link to="/about#terms" className="footer-item">Terms</Link>
            </div>
        </div>
    )
}

export default Footer;