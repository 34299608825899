import React from 'react';
import './Main.css';
import ProductCard from '../Components/ProductCard';

const Payment = () => {
    return (
        <div className='product-container'>
            <ProductCard />
            <div className='product-image-container'>
                <img src='/donbot-dealhunter.png' alt='placeholder' className='product-image'/>
            </div>
        </div>
    );
}

export default Payment;
