import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Link } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import "../styles/Components.css";
import useDynamicStyles from '../styles/DynamicStyles';
import { Box } from '@mui/material';
import { useUserSettings } from '../../../Contexts/UserSettingsContext';
import { VERIFYSTATUS } from '../../Pages/VerifyEmail';

// Define constants for the verification states
const UNVERIFIED = 0;
const IS_VERIFYING = 1;
const VERIFIED = 2;


export interface FormDialogProps {
    handleSubmit: (email: string) => Promise<any>;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const FormDialog = (props: FormDialogProps) => {

  const styles = useDynamicStyles();
  const { state } = useUserSettings();
  const [verificationState, setVerificationState] = React.useState(state.userData.verified ? VERIFIED : UNVERIFIED);


  const handleClose = () => {
    props.setOpen(false);
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Prevent form submission from reloading the page
    setVerificationState(IS_VERIFYING); // Start verifying
    
    const formData = new FormData(event.currentTarget);
    const email = formData.get('email') as string; // Get the email from form data

    props.handleSubmit(email)
      .then((status) => {

        if (status === VERIFYSTATUS.PENDING) {
          return;
        } else if (status === VERIFYSTATUS.VERIFIED) {
          setVerificationState(VERIFIED); // Set to verified state
        } else {
          setVerificationState(UNVERIFIED); // Set to unverified state
          // Here, handle the error, e.g., show an error message, and allow the user to try again
        }
      })
      .catch((error) => {
        console.error("Email verification failed:", error.message);
        setVerificationState(VERIFIED); // Set to verified state
        // Here, handle the error, e.g., show an error message
      });
  };

  React.useEffect(() => {
    if (state.userData.verified) {
      setTimeout(() => {
        setVerificationState(VERIFIED); // Set to verified state
        props.setOpen(false);
      }, 3000); // Close the dialog after 2.5 seconds
    }
  }, [state.userData.verified, props]);

  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        onClose={handleClose}
        sx={styles.dialog}
        PaperProps={{
          component: 'form',
          onSubmit: onSubmit,
        }}
      >
        <DialogTitle>Join the Donum Robotum Community</DialogTitle>
        <DialogContent sx={{justifyContent: 'center'}}>
          <DialogContentText sx={{ color: !state.darkMode ? '#282c34' : '#e0e0e0' }}>
          Stay ahead in finding the best deals with Donum Robotum! Enter your email to start searching now. 
          We respect your privacy and promise only to send you updates and deals that matter.&nbsp;
          <b>You'll be able to opt-out anytime.</b>
          </DialogContentText>
          {verificationState === UNVERIFIED && (
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
          />
          )}
          {verificationState === IS_VERIFYING && (
          <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
            <CircularProgress sx={{maxWidth: '25px', maxHeight: '25px', color: '#71798a'}} />
          </Box>
          )}
          {verificationState === VERIFIED && (
          <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
            <h4>
              Email verified!
            </h4>
          </Box>
          )}
        </DialogContent>
        <DialogActions sx={{justifyContent: 'center'}}>
          <div className='dialog-bottom-container'>
            <DialogContentText sx={{fontSize: '11px', color: '#71798a'}}>
              By entering your email, you agree to our&nbsp;
              <Link target="_blank" rel="noopener noreferrer" to="/about#privacy">Privacy Policy</Link>
            </DialogContentText>
          {verificationState === UNVERIFIED && (
              <>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit">Enter</Button>
              </>
          )}
          {verificationState === IS_VERIFYING && (
            <h4>
              Awaiting verification...
            </h4>
          )}
          {verificationState === VERIFIED && (
            <h4>
              Closing...
            </h4>
          )}
          </div>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default FormDialog;